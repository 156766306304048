<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">First Name</label>
              <input v-model="firstName" type="text" />
            </div>
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">Last Name</label>
              <input v-model="lastName" type="text" />
            </div>
          </div>
          <label class="text-left required">Email</label>
          <input v-model="email" type="email" />
          <label class="text-left required">Account Type</label>
          <select v-model="accessLevel">
            <option value="5">Crew</option>
            <option value="9">Production</option>
            <option value="10">Admin</option>
          </select>
          <label class="text-left">Birthday</label>
          <InputDatePicker v-model="birthday"></InputDatePicker>
          <label class="text-left">Phone Number</label>
          <input v-model="phoneNumber" type="text" />
          <label class="text-left">Profession</label>
          <div
            class="pane-right gap-3"
            v-for="(proffession, index) in userCategories"
            :key="proffession.id"
          >
            <select v-model="proffession.name">
              <option v-for="category in categories" :key="category">
                {{ category }}
              </option>
            </select>
            <button class="edit" @click.prevent="removeProffession(index)">
              X
            </button>
          </div>
          <button class="add" @click.prevent="addProffession()">Add</button>
          <label class="text-left">Address</label>
          <textarea v-model="address" />
          <label class="text-left">Guardian</label>
          <textarea v-model="guardian" placeholder="name, number" />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const generator = require("generate-password");
import { v4 as uuidv4 } from "uuid";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      phoneNumber: "",
      address: "",
      guardian: "",
      accessLevel: 5,
      userCategories: [],
      categories: [],
    };
  },
  components: {
    InputDatePicker,
  },
  methods: {
    ...mapActions(["addUser", "getPricelistItems"]),
    addProffession() {
      this.userCategories.push({
        name: "",
        dailyFee: 0,
        id: uuidv4(),
      });
    },
    removeProffession(index) {
      this.userCategories.splice(index, 1);
    },
    submit() {
      const password = generator.generate({
        length: 8,
        numbers: true,
      });

      this.addUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: password,
        accessLevel: this.accessLevel,
        birthday: this.birthday,
        phoneNumber: this.phoneNumber,
        address: this.address,
        guardian: this.guardian,
        categories: this.userCategories,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelistItems()
      .then((items) => {
        const services = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .map((item) => item.name)
          )
        );

        this.categories = services;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>